import debounce from 'lodash/debounce';
import {
  shopSearchCompletionKeywordsResponse
} from "@/src/web/regions/utils/requestShopSearchCompletionKeywords";

export type SelectRegionFieldParams = {
  elementId?: string;
  inputChangingNameSelector?: string;
  candidateWrapperSelector?: string;
  candidateMessageWrapperSelector?: string;
}

type SelectRegionFieldBindEventParams = {
  updateChangingName: (event: InputEvent) => void;
}

export default class SelectRegionField {
  shopFilterSelectRegionFieldsRef: HTMLElement;
  inputChangingNameRef: HTMLInputElement;
  candidateWrapperRef: HTMLElement;
  candidateMessageWrapperRef: HTMLElement;

  constructor({
    elementId = 'shop_filter_select_region_fields',
    inputChangingNameSelector = 'input[name="shop_fetcher[name]"]',
    candidateWrapperSelector = '.candidates_wrapper',
    candidateMessageWrapperSelector = '.candidates_message_wrapper'
  }: SelectRegionFieldParams) {
    this.shopFilterSelectRegionFieldsRef = document.getElementById(elementId);
    this.inputChangingNameRef = this.shopFilterSelectRegionFieldsRef.querySelector(inputChangingNameSelector);
    this.candidateWrapperRef = this.shopFilterSelectRegionFieldsRef.querySelector(candidateWrapperSelector);
    this.candidateMessageWrapperRef = this.shopFilterSelectRegionFieldsRef.querySelector(candidateMessageWrapperSelector);
  }

  bindEventHandler(params: SelectRegionFieldBindEventParams) {
    this.inputChangingNameRef.addEventListener('click', debounce(params.updateChangingName, 200));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call
    this.inputChangingNameRef.addEventListener('input', debounce(params.updateChangingName, 200));
  }

  getInputShopFetcherNameValue() {
    return this.inputChangingNameRef.value;
  }

  setInputShopFetcherNameValue(value: string) {
    this.inputChangingNameRef.value = value;
  }

  hideCandidatesAndMessage() {
    this.hideCandidates();
    this.hideCandidateMessage();
  }

  hideCandidateMessage() {
    this.candidateMessageWrapperRef.classList.add('hidden');
  }

  showCandidateMessage() {
    this.candidateMessageWrapperRef.classList.remove('hidden');
  }

  hideCandidates() {
    this.candidateWrapperRef.classList.add('hidden');
  }

  showCandidates() {
    this.candidateWrapperRef.classList.remove('hidden');
  }

  insertNames(nameResponse: shopSearchCompletionKeywordsResponse[], setRegionHandler: (region: shopSearchCompletionKeywordsResponse) => void) {
    const candidates = this.candidateWrapperRef.querySelector('.candidates');
    this.hideCandidateMessage();
    candidates.innerHTML = '';
    if (nameResponse.length === 0) {
      return;
    }

    nameResponse.forEach((name) => {
      const candidate = document.createElement('a');
      candidate.classList.add('candidate');
      candidate.textContent = name.content;
      candidate.addEventListener('click', () => {
        setRegionHandler(name);
      });
      candidates.appendChild(candidate);
    });
    this.showCandidates();
  }

  toggleNameIconActive = () => {
    const inputNameFields = this.shopFilterSelectRegionFieldsRef.querySelector('.field_wrapper.name');
    const value = this.getInputShopFetcherNameValue();
    if (value) {
      inputNameFields.classList.add('active');
    } else {
      inputNameFields.classList.remove('active');
    }
  }
}
