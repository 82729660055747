export type ShopFilterFormParams = {
  elementId?: string;
  submitButtonSelector?: string;
}

export default class ShopFilterForm {
  shopFilterFormRef: HTMLElement;
  shopFilterFormSubmitButtonRef: HTMLInputElement;

  constructor({ elementId = 'shop_filter', submitButtonSelector = 'input[type="submit"]' }: ShopFilterFormParams) {
    this.shopFilterFormRef = document.getElementById(elementId);
    this.shopFilterFormSubmitButtonRef = this.shopFilterFormRef.querySelector(submitButtonSelector);
  }

  show() {
    this.shopFilterFormRef.classList.remove('hidden');
  }

  setInputShopFetcherValueByName(name: string, value: string) {
    const elems = document.getElementsByName(`shop_fetcher[${name}]`) as unknown as HTMLInputElement[];
    if (elems) {
      elems[0].value = value;
    }
  }

  enableSubmittable = () => {
    this.shopFilterFormSubmitButtonRef.classList.add('attention');
    this.shopFilterFormSubmitButtonRef.classList.remove('disabled');
    this.shopFilterFormSubmitButtonRef.disabled = false;
  }

  disableSubmittable = () => {
    this.shopFilterFormSubmitButtonRef.classList.remove('attention');
    this.shopFilterFormSubmitButtonRef.classList.add('disabled');
    this.shopFilterFormSubmitButtonRef.disabled = true;
  }
}
