import debounce from 'lodash/debounce';
import {
  shopSearchCompletionKeywordsResponse
} from "@/src/web/regions/utils/requestShopSearchCompletionKeywords";
import {removeChainSuffix} from "@/src/web/regions/utils/stringUtils";

export type SelectQueryFieldParams = {
  elementId?: string;
  inputChangingQuerySelector?: string;
  candidateWrapperSelector?: string;
  candidateMessageWrapperSelector?: string;
  inputFreeWordQuerySelector?: string;
}

type SelectQueryFieldBindEventParams = {
  updateChangingQuery: (event: InputEvent) => void;
}

export default class SelectQueryField {
  shopFilterSelectQueryFieldsRef: HTMLElement;
  inputChangingQueryRef: HTMLInputElement;
  inputFreeWordQueryRef: HTMLInputElement | null;
  candidateWrapperRef: HTMLElement;
  candidateMessageWrapperRef: HTMLElement;

  constructor({
    elementId = 'shop_filter_select_query_fields',
    inputChangingQuerySelector = 'input[name="shop_fetcher[query]"]',
    candidateWrapperSelector = '.candidates_wrapper',
    candidateMessageWrapperSelector = '.candidates_message_wrapper',
    inputFreeWordQuerySelector = null,
  }: SelectQueryFieldParams) {
    this.shopFilterSelectQueryFieldsRef = document.getElementById(elementId);
    this.inputChangingQueryRef = this.shopFilterSelectQueryFieldsRef.querySelector(inputChangingQuerySelector);
    this.candidateWrapperRef = this.shopFilterSelectQueryFieldsRef.querySelector(candidateWrapperSelector);
    this.candidateMessageWrapperRef = this.shopFilterSelectQueryFieldsRef.querySelector(candidateMessageWrapperSelector);
    this.inputFreeWordQueryRef = inputFreeWordQuerySelector ? this.shopFilterSelectQueryFieldsRef.querySelector(inputFreeWordQuerySelector) : null;
  }

  bindEventHandler(params: SelectQueryFieldBindEventParams) {
    if (this.inputFreeWordQueryRef) {
      this.inputFreeWordQueryRef.addEventListener('click', debounce(params.updateChangingQuery, 200));
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call
      this.inputFreeWordQueryRef.addEventListener('input', debounce(params.updateChangingQuery, 200));
    } else {
      this.inputChangingQueryRef.addEventListener('click', debounce(params.updateChangingQuery, 200));
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call
      this.inputChangingQueryRef.addEventListener('input', debounce(params.updateChangingQuery, 200));
    }
  }

  isFreeWordQueryMode() {
    return !!this.inputFreeWordQueryRef;
  }

  setInputChangingQueryValue(value: string) {
    if (this.inputFreeWordQueryRef) {
      this.inputFreeWordQueryRef.value = value;
    } else {
      this.inputChangingQueryRef.value = value;
    }
  }

  getInputChangingQueryValue() {
    if (this.inputFreeWordQueryRef) {
      return this.inputFreeWordQueryRef.value;
    } else {
      return this.inputChangingQueryRef.value;
    }
  }

  getInputFreeWordQueryRefValue() {
    if (this.inputFreeWordQueryRef) {
      return this.inputFreeWordQueryRef.value;
    } else {
      return '';
    }
  }

  hideCandidatesAndMessage() {
    this.hideCandidates();
    this.hideCandidateMessage();
  }

  hideCandidateMessage() {
    this.candidateMessageWrapperRef.classList.add('hidden');
  }

  showCandidateMessage() {
    this.candidateMessageWrapperRef.classList.remove('hidden');
  }

  hideCandidates() {
    this.candidateWrapperRef.classList.add('hidden');
  }

  showCandidates() {
    this.candidateWrapperRef.classList.remove('hidden');
  }

  insertQueries(nameResponse: shopSearchCompletionKeywordsResponse[], setQueryHandler: (query: shopSearchCompletionKeywordsResponse) => void) {
    const candidates = this.candidateWrapperRef.querySelector('.candidates');
    this.hideCandidateMessage();
    candidates.innerHTML = '';
    if (nameResponse.length === 0) {
      return;
    }

    nameResponse.forEach((name) => {
      const candidate = document.createElement('a');
      candidate.classList.add('candidate');
      candidate.textContent = removeChainSuffix(name.content);
      candidate.addEventListener('click', () => {
        setQueryHandler(name);
      });
      candidates.appendChild(candidate);
    });
    this.showCandidates();
  }

  toggleQueryIconActive = () => {
    const inputQueryFields = this.shopFilterSelectQueryFieldsRef.querySelector('.field_wrapper.query');
    const value = this.getInputChangingQueryValue();
    if (value) {
      inputQueryFields.classList.add('active');
    } else {
      inputQueryFields.classList.remove('active');
    }
  }
}
