// @see
// app/views/bargain/shared/regions/_shop_filter.html.haml
// app/javascript/packs/components_regions_shop_filter_pc.vue.vue
import SelectQueryField, {SelectQueryFieldParams} from "@/src/web/regions/pc/ShopFilterForm/SelectQueryField";
import SelectRegionField, {SelectRegionFieldParams} from "@/src/web/regions/pc/ShopFilterForm/SelectRegionField";
import ShopFilterForm, {ShopFilterFormParams} from "@/src/web/regions/pc/ShopFilterForm/ShopFilterForm";
import {ShopFetcher} from "@/src/web/regions/types";
import {
  shopSearchCompletionKeywords,
  shopSearchCompletionKeywordsResponse
} from "@/src/web/regions/utils/requestShopSearchCompletionKeywords";

type ShopFilterFormManagerParams = {
  elementIdWithFormData?: string;
  shopFilterFormParameters?: ShopFilterFormParams;
  selectRegionFieldParameters?: SelectRegionFieldParams;
  selectQueryFieldParameters?: SelectQueryFieldParams;
}

export default class ShopFilterFormManager {
  shopFetcher: ShopFetcher;
  shopFilterForm: ShopFilterForm;
  shopFilterSelectRegionField: SelectRegionField;
  shopFilterSelectQueryField: SelectQueryField;

  constructor({
    elementIdWithFormData = 'shop_filter',
    shopFilterFormParameters = {},
    selectRegionFieldParameters: selectRegionFieldParameters = {},
    selectQueryFieldParameters = {}
  }: ShopFilterFormManagerParams) {
    this.init(elementIdWithFormData);
    const freeWordQuerySelector = 'input[name="shop_fetcher[free_word_query]"]';
    const freeWordQueryField = document.querySelector(freeWordQuerySelector);

    this.shopFilterForm = new ShopFilterForm(shopFilterFormParameters);
    this.shopFilterSelectRegionField = new SelectRegionField(selectRegionFieldParameters);
    this.shopFilterSelectQueryField = new SelectQueryField({ ...selectQueryFieldParameters, inputFreeWordQuerySelector: freeWordQueryField ? freeWordQuerySelector : null });

    this.shopFilterSelectRegionField.bindEventHandler({
      updateChangingName: this.updateChangingName,
    });
    this.shopFilterSelectQueryField.bindEventHandler({
      updateChangingQuery: freeWordQueryField ? this.updateFreeWordQuery : this.updateChangingQuery,
    });

    this.validateSubmittable();
    this.shopFilterForm.show();
  }

  init(elementId: string) {
    const formData = document.getElementById(elementId);
    if (!formData) return;

    window.addEventListener('click', () => {
      this.cancelSettingCandidates()
    }, false);
    formData.addEventListener('click', (event) => {
      event.stopPropagation()
    }, false);

    if (formData.dataset && formData.dataset.form) {
      this.shopFetcher = JSON.parse(formData.dataset.form) as ShopFetcher;
    }
  }

  updateChangingName = async (event: InputEvent) => {
    this.shopFilterSelectRegionField.hideCandidateMessage();
    const name = 'value' in event.target ? event.target.value as string : '';
    if (!name) {
      this.updateShopFetcher({
        target_type: 'all_region',
        target_id: null,
      });
      this.updateInputAttributes({content: '', category: 'all_region', relation_id: '', prefecture_id: 0}, 'name');
      this.shopFilterSelectRegionField.hideCandidatesAndMessage();
      this.validateSubmittable();
      return;
    }
    const response = await shopSearchCompletionKeywords({ query: name, query_type: 'region', prefecture_id: this.shopFetcher.prefecture_id });
    if (response.length === 0) {
      this.shopFilterSelectRegionField.hideCandidatesAndMessage();
      this.shopFilterSelectRegionField.showCandidateMessage();
      return;
    }
    this.shopFilterSelectRegionField.insertNames(response, this.setRegion);
  }

  setRegion = (region: shopSearchCompletionKeywordsResponse) => {
    this.updateShopFetcher({
      name: region.content,
      target_type: region.category,
      target_id: region.relation_id,
      prefecture_id: region.prefecture_id,
    });

    this.updateInputAttributes(region, 'name');
    this.shopFilterSelectRegionField.hideCandidatesAndMessage();
    this.validateSubmittable();
  }

  updateChangingQuery = async (event: InputEvent) => {
    this.shopFilterSelectQueryField.hideCandidateMessage();
    const query = 'value' in event.target ? event.target.value as string : '';
    if (!query) {
      this.updateShopFetcher({
        query: null,
      });
      this.updateInputAttributes({content: '', category: '', relation_id: '', prefecture_id: 0}, 'query');
      this.shopFilterSelectQueryField.hideCandidatesAndMessage();
      this.validateSubmittable();
      return;
    }
    const response = await shopSearchCompletionKeywords({ query: query, query_type: 'chain', prefecture_id: this.shopFetcher.prefecture_id });
    if (response.length === 0) {
      this.shopFilterSelectQueryField.hideCandidatesAndMessage();
      this.shopFilterSelectQueryField.showCandidateMessage();
      return;
    }
    this.shopFilterSelectQueryField.insertQueries(response, this.setQuery);
    this.validateSubmittable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateFreeWordQuery = (event: InputEvent) => {
    this.validateSubmittable();
  }

  setQuery = (query: shopSearchCompletionKeywordsResponse) => {
    this.updateShopFetcher({
      query: query.content,
    });
    this.updateInputAttributes(query, 'query');
    this.shopFilterSelectQueryField.hideCandidatesAndMessage();
    this.validateSubmittable();
  }

  overrideQueryValueByShopFetcher = () => {
    if (!this.shopFilterSelectQueryField.isFreeWordQueryMode()) {
      this.updateInputAttributes({ content: this.shopFetcher.query, category: '', prefecture_id: 0, relation_id: '' }, 'query');
    }
  }

  updateInputAttributes = (params: shopSearchCompletionKeywordsResponse, targetInputName: 'name' | 'query') => {
    if (targetInputName === 'name') {
      this.shopFilterSelectRegionField.setInputShopFetcherNameValue(params.content ?? '');
      this.shopFilterForm.setInputShopFetcherValueByName('target_type', params.category ?? '');
      this.shopFilterForm.setInputShopFetcherValueByName('target_id', `${params.relation_id ?? ''}`);
    } else {
      this.shopFilterSelectQueryField.setInputChangingQueryValue(params.content ?? '');
    }
  }

  validateSubmittable = () => {
    this.shopFilterSelectRegionField.toggleNameIconActive();
    this.shopFilterSelectQueryField.toggleQueryIconActive();

    const freeWordQueryValue = this.shopFilterSelectQueryField.getInputFreeWordQueryRefValue();

    if (
      (this.shopFetcher.name && this.shopFetcher.target_id) || this.shopFetcher.query || freeWordQueryValue) {
      this.shopFilterForm.enableSubmittable();
    } else {
      this.shopFilterForm.disableSubmittable();
    }
  }

  updateShopFetcher = (params: { [key: string]: string | number | null }) => {
    this.shopFetcher = { ...this.shopFetcher, ...params }
  }

  cancelSettingCandidates = () => {
    this.shopFilterSelectRegionField.hideCandidatesAndMessage();
    this.overrideQueryValueByShopFetcher();
    this.shopFilterSelectQueryField.hideCandidatesAndMessage();
    this.validateSubmittable();
  }
}
